import React, { useEffect, useState } from "react";
import Script from 'next/script'
import { useRouter } from "next/router";
import { includes } from "lodash";
import { catalistPixelKey } from "config";

export function FBPixel ({ pixelCode }) {
  const [dynamicScript, setDynamicScript] = useState(<></>);
  const [pixelCodesList, setPixelCodesList] = useState([catalistPixelKey]);
  const router = useRouter()

  const pageview = () => {
    // exists check for page redirects
    if(typeof window !== "undefined" && typeof window.fbq === 'function') window.fbq('track', 'PageView')
  }

  const handleRouteChange = () => {
    pageview()
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // We are dynamically rendering the script tag which holds the FB Pixel information to allow both ourselves and issuers to track information.
  // This will allow an additional tag to be rendered if an issuer supplies a pixel code for themselves, if not then we will only render out FB pixel tag.

  useEffect(() => {
    let codes = pixelCodesList;    
    if (pixelCode && !includes(pixelCodesList, pixelCode)) {
      codes = [...pixelCodesList, pixelCode];
    }
    
    //!!!!!!! DO NOT TOUCH - The format of the script below cannot change. It is whitespace sensitive (horizontal & vertical) and will break the matched CORS hash.
    setDynamicScript(
      <>
        {codes.map((code, i) => {
          return (<Script className="facebook-pixel" key={i}>
            {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${code}');
              fbq('track', 'PageView');`}
          </Script>
          )
        })}
      </>
    );
    setPixelCodesList(codes);
  }, [pixelCode]);
  return dynamicScript;
}
