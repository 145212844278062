import { NextSeo } from 'next-seo';

export function StandardSeo({ titleProp, descriptionProp, keywordProp, imageURL='https://cdn.catalist.co.nz/website_assets/Catalist_HeroImage_SocialSharing.png' }) {
return (
    <NextSeo
      title={titleProp}
      description={descriptionProp}
      /*canonical={window.location.href}*/
      openGraph={{
        /*url: window.location.href,*/
        title: titleProp,
        description: descriptionProp,
        images: [
          {
            url: imageURL,
            width: 800,
            height: 600,
            alt: 'Catalist logo',
          },
        ],
        site_name: 'Catalist',
        locale: 'en_NZ'
      }}
      robotsProps={{
        maxImagePreview: "large"
      }}
      additionalMetaTags={[{
        property: 'keywords',
        content: keywordProp
      }]}
    />
  );
}
