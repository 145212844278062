// SEO is to be tailored to each specific page so they have unique titles and descriptions which help promote our standing in search rankings.

// Default SEO tags - call them default rather than standard
const defaultSEO = { title: 'Catalist | The stock exchange for New Zealand\'s growth economy', description: 'The simplest way for investors and businesses to connect and grow with unrivalled access to NZ\'s growth marketplace.', keywords: 'Growth stock exchange, investors, SMEs, share marketplace, capital markets, trading, shares, startups' }

// Invest
const investSEO = { title: 'Invest | Retail, Wholesale, Angel - Catalist', description: 'Diversify your investment portfolio with the stock exchange for growing Kiwi businesses.', keywords: 'Investment, Growth Businesses, portfolio diversification' }

// List
const listSEO = { title: 'List | Trade, Raise Capital - Catalist', description: 'Unlock the power of tradable shares and stimulate new avenues of investments and raising capital for your business through Catalist marketplace.', keywords: 'SMEs, Growth Businesses, investment, capital raise, share trading, registry' }

// Market
const marketSEO = { title: 'Investment marketplace | Public, Wholesale, Angel - Catalist', description: 'The investment marketplace where investors can view investments in a diverse portfolio of New Zealand’s growth businesses, SMEs and startups.', keywords: 'Listed businesses, investments, SMEs, share marketplace, retail investors, wholesale investors, angel investors' }

// For Businesses
const businessSEO = { title: 'For Businesses - Catalist', description: 'Catalist is New Zealand’s only licensed and regulated stock exchange designed for medium-sized businesses looking to grow through investment.', keywords: 'SMEs, Growth Businesses, investment, capital raise, share trading, registry' }

// For Investors
const investorsSEO = { title: 'For Investors - Catalist', description: 'Investors have new investment opportunities to diversify your investment portfolio with the stock exchange for growing Kiwi businesses.  Open to all investors.', keywords: 'Investment, Growth Businesses, portfolio diversification' }

// Blogs
const blogsSEO = { title: 'Blog - Catalist', description: 'Information, tips and investing news from Catalist and the marketplace for New Zealand’s growth investments .', keywords: 'Blog, news, Growth Businesses, investment, capital raise, share trading' }

// Contact 
const contactSEO = { title: 'Contact Us - Catalist', description: 'Any suggestions, questions or feedback? Reach us here and we will be happy to help', keywords: 'customer service' }

// FAQ
const faqSEO = { title: 'Frequently Asked Questions - Catalist', description: 'The answers to some of our most frequently asked questions about investments, investing and listing a business', keywords: 'FAQs, customer service, How to' }

// Fees
const feesSEO = { title: 'Fees - Catalist', description: 'A description of the fees you might need to pay to buy or sell investments in our growth marketplace.', keywords: 'Fees, costs' }

// Documentation 
const documentationSEO = { title: 'Documentation - Catalist', description: 'This is the one stop shop for all you need to know about investing, listing and other information related to our growth investment marketplace.', keywords: 'Terms and conditions, policies, guidance, documentation' }

// How Auctions Work - Basic
const howAuctionsWorkBasicSEO = { title: 'Catalist - How Auctions Work - Basics', description: 'Learn how auctions work and the mechanics of the marketplace. Understand the types and priorities of bids (to buy) and offers (to sell).', keywords: 'Marketplace mechanics, priority of investment, auction algorithm' }

// How Auctions Work - Technical
const howAuctionsWorkTechnicalSEO = { title: 'Catalist - How Auctions Work - Technical', description: 'A more detailed look at how auctions work and how successful and unsuccessful bids and offers are calculated.', keywords: 'Marketplace mechanics, priority of investment, auction algorithm' }

// About 
const aboutUsSEO = { title: 'About Us - Catalist', description: 'Welcome to Catalist New Zealand’s only licensed and regulated stock exchange designed to unlock new investment opportunities .', keywords: 'about, team, about us, about Catalist, investing, invest' }

// Sign up
const signUpSEO = { title: 'Sign up - Catalist', description: 'Sign up for a Catalist Investor account here and access the market place with investment offerings from a variety of New Zealand growth businesses.', keywords: 'sign up'}

// Marketplace mechanics
const marketplaceMechanicsSEO = { title: 'Marketplace Mechanics - Catalist', description: 'Learn how auctions work and the mechanics of the marketplace. Understand the types and priorities of bids (to buy) and offers (to sell).', url: 'https://www.catalist.co.nz/MarketplaceMechanics', keywords: 'Marketplace mechanics, priority of investment, auction algorithm'}

// Media
const mediaSEO = { title: 'Media - Catalist', description: 'Catalist media coverage across articles, podcasts and video.', url: 'https://www.catalist.co.nz/Media', keywords: 'Media, press, coverage'}

// Calendar
const calendarSEO = { title: 'Catalist - Auction schedule', description: 'Catalist upcoming scheduled auctions.', url: 'https://www.catalist.co.nz/Schedule', keywords: 'schedule, auction, calendar, dates, upcoming, future'}


export {
  defaultSEO,
  marketSEO,
  businessSEO,
  investorsSEO,
  blogsSEO,
  contactSEO,
  faqSEO,
  feesSEO,
  documentationSEO,
  howAuctionsWorkBasicSEO,
  howAuctionsWorkTechnicalSEO,
  aboutUsSEO,
  signUpSEO,
  investSEO,
  listSEO,
  marketplaceMechanicsSEO,
  mediaSEO,
  calendarSEO
}