import { useContext } from 'react';

import { AppContext } from '../../pages/_app';
import { Button } from 'semantic-ui-react';


export const SidebarOpener = ({linkText, className, isHyperlink, color, style, size}) => {

	const {handleSidebarConfigChange } = useContext(AppContext);

	/**
	 * Check local storage in case where a user has clicked through an email link
	 */

	let type = 'SIGNUP_INVESTOR';
	if(typeof window !== "undefined"){
		if(window.localStorage.getItem('submitToken')) type = 'SIGNUP_INVESTOR_KEY_PERSON';
		if(window.localStorage.getItem('joinGroupSignUpToken')) type = 'SIGNUP_INVESTOR_JOIN_GROUP';
	};
	
	return (
		<>
			{ !isHyperlink &&
				<Button
					color={color}
					style={style}
					size={size}
					className={className}
					onClick={() => handleSidebarConfigChange({open: true, type}
				)}>
					{linkText ? linkText : 'Join'}
				</Button>
			}

			{ isHyperlink &&
				<a 
					style={{cursor: 'pointer'}}
					className={className}
					onClick={() => handleSidebarConfigChange({ open: true, type}
				)}>
					{linkText ? linkText : 'Join'}
				</a>
			}
		</>
	)
}
