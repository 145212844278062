const dateFormat = 'MMM do yyyy';
const dateTimeFormat = 'dd/MM/yyyy h:mm a';
const googleAPIKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
const catalistPixelKey = process.env.NEXT_PUBLIC_FB_PIXEL_KEY;

export {
  dateFormat,
  dateTimeFormat,
  googleAPIKey,
  catalistPixelKey
}
