import React from 'react'
import { Container, Ref, Sticky } from 'semantic-ui-react';

import HeaderMenu from 'components/core/AppBar';
import Footer from 'components/core/Footer';

export default function HomeLayout(props) {
	const contextRef = React.createRef();
	return (
		<Ref innerRef={contextRef}>
			<Container fluid>
				<div 
					style={{
						width:'100%', 
                        // Bugfix for vertical top scroll: sidebar appearing behind page content
                        marginTop: '-1px',
                        // set rail height prevents masking of page content
                        height: '0px'
					}}>
					<Sticky context={contextRef}>
						<HeaderMenu {...props} />
					</Sticky>
				</div>
				{props.children}
				<Footer className='footer' />
			</Container>
		</Ref>
	);
};
